import React from 'react';
import { Layout } from 'antd'
import './style.scss';
import AppFooter from "../../components/Footer";
import logo from '../../images/logo/logo.svg'
import Clock from '../../components/Clock'
import AppHeader from "../../components/Header";

const {
    Footer, Content,
} = Layout;

class App extends React.Component {

    render() {
        return (
            <Layout className="layout">
                <AppHeader/>
                <Content>
                    <header className="App-header">
                        {/*这里只有一个logo*/ }
                        <img src={ logo } className="App-logo" alt="logo"/>
                        <Clock/>
                    </header>
                </Content>

                <Footer>
                    <AppFooter/>
                </Footer>
            </Layout>
        )
    }
}

export default App;
