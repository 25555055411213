import React from 'react';
import './style.scss';

function FormattedDate(props) {
    return <p><br/> { props.date.toLocaleTimeString() }</p>;
}

class Clock extends React.Component {


    constructor(props) {
        super(props);
        this.state = { date: new Date() };
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            1000
        );
    }


    /**
     *
     */
    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    /**
     * 时钟
     */
    tick() {
        this.setState({
            date: new Date()
        });
    }

    /**
     * 渲染画面
     * @returns {*}
     */
    render() {
        return (
            <div>
                <FormattedDate date={ this.state.date }/>
            </div>
        );
    }


}


export default Clock;
