import React from 'react';
import { Icon, Menu } from "antd";
import './style.scss'
import Banner from '../../images/banner/bannger_bg.svg'

const SubMenu = Menu.SubMenu;

class Navbar extends React.Component {
    state = {
        current: 'mail',
    };

    handleClick = (e) => {
        console.log('click ', e);
        this.setState({
            current: e.key,
        });
    };

    render() {
        return (
            <div className="navbar">
                <Menu
                    onClick={ this.handleClick }
                    selectedKeys={ [this.state.current] }
                    mode="horizontal"
                >
                    <Menu.Item key="banner" className="banner">
                        <img src={ Banner } alt="banner"/>
                    </Menu.Item>

                    <Menu.Item key="mail">
                        <Icon type="mail"/>首页
                    </Menu.Item>

                    <Menu.Item key="app">
                        <Icon type="appstore"/>发展历程
                    </Menu.Item>

                    <SubMenu title={ <span className="submenu-title-wrapper"><Icon
                        type="setting"/>产品介绍</span> }>
                        <Menu.Item key="setting:1">样机酷</Menu.Item>
                        <Menu.Item key="setting:2">大学宝</Menu.Item>
                    </SubMenu>

                    <Menu.Item key="alipay">联系我们</Menu.Item>

                </Menu>
            </div>
        )
    }


}


export default Navbar;
