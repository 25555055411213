import React from 'react';
import './style.scss';

class AppFooter extends React.Component {


    render() {
        return (<div className="app-footer">学仕科技 版权所有 2019-2020 浙ICP备8888888</div>)
    }

}


export default AppFooter;
