import React from 'react';
import './style.scss';
import Navbar from "../Navbar";

class AppHeader extends React.Component {

    render() {
        return (
            <Navbar/>
        )
    }
}

export default AppHeader;
